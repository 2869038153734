import { callApi } from 'utils/api'
import crud from './_crud'

const entity = 'announcement'
const messageEntity = 'El comunicado'
const messagePluralEntity = 'Los comunicados'
const actions = crud(entity, messageEntity, messagePluralEntity)

export const getAnnouncementsFromVisitedCompanies = (options={}) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi('/announcement/get_announcements_from/visited_companies', {
			headers: {
				Authorization: state.app.client.token,
			},
			params: options.params,
		})
		
		if(res.status == 200){
			dispatch({
				type: `LOAD_${entity.toUpperCase()}S`,
				response: res.body,
			})

			return res.body
		}

		return []
	}
}

const getAnnouncements = (options = {}) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi('/announcement/get_announcements', {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
				params: options.params
			})

			dispatch({ type: 'LOAD_ANNOUNCEMENTS', response: res.body })
			return res.body
		} catch (error) {}
	}
}
const getAnnouncementsById = (id) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/announcement/get_announcements/${id}`, {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			})

			dispatch({ type: 'LOAD_ANNOUNCEMENTS_BY_ID', payload: res.body })
		} catch (error) {}
	}
}

//Crear un comunicado
const createAnnouncement = (announcement,image) => {
    return async (dispatch, getState) => {
        const state = getState()

        try {
            const res = await callApi(`/announcement/create_announcement`, {
                method: 'POST',
				json: false,
                headers: {
                    Authorization: state.app.user.token,
                },
                // body: JSON.stringify({ announcement }),
				body: {
					image: image,
					item: JSON.stringify({announcement}),
				},
                successMessage: 'Comunicado creado correctamente',
            })
            return true;
        } catch (error) {
            // Manejar error
			console.log("ERROR");
            return false;
        }
    }
}


//Modificar un comunicado
const updateAnnouncement = (announcement,image) => {
    return async (dispatch, getState) => {
        const state = getState()

        try {
            const res = await callApi(`/announcement/update_announcement`, {
                method: 'PUT',
				json: false,
                headers: {
                    Authorization: state.app.user.token,
                },
                body: {
					image: image,
					item: JSON.stringify({announcement}),
				},
                successMessage: 'Comunicado actualizado correctamente',
            })
            return true;
        } catch (error) {
            // Manejar error
            return false;
        }
    }
}

const deleteAnnouncement = (id) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/announcement/delete_announcements/${id}`, {
				method: 'DELETE',
				headers: {
					Authorization: state.app.user.token,
				},
				successMessage: 'Registro eliminado correctamente',
			})
		} catch (error) {
			// Manejar error
		}
	}
}

const deleteAnnouncementsMultiple = (ids) => {
    return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/announcement/delete_announcements_multiple`, {
				method: 'DELETE',
				headers: {
					Authorization: state.app.user.token,
				},
                body: JSON.stringify({ ids }),
				successMessage: 'Registro eliminado correctamente',
			})
		} catch (error) {
			// Manejar error
		}
	}
}

export default {
	...actions,
	getAnnouncements,
    getAnnouncementsById,
    createAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    deleteAnnouncementsMultiple,
    
}