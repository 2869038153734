import crudHandlers from './_crud'

const initialState = {
  infoStall : [],
  entities: {},
  is_fetching: false,
  active: undefined,
}

export default (state= initialState,action) => {
  switch (action.type) {
    case 'INFO_STALL': {

      return{
        ...state,
        infoStall: action.res
      }
    }

    default: {
      return crudHandlers(state, action, 'category_food_stall')
    }
  }
}
