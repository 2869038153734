import { callApi } from 'utils/api'
import crud from './_crud'

const entity = 'offer'
const messageEntity = 'la oferta'
const messagePluralEntity = 'las ofertas'
const actions = crud(entity, messageEntity, messagePluralEntity)

const getOffers = (options = {}) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi('/offer/get_offers', {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
				params: options.params
			})

			dispatch({ type: 'LOAD_OFFERS', response: res.body })
			
			return res.body;
		} catch (error) {
			console.log('Error al cargar las ofertas')
		}
	}
}
const getOffersById = (id) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/offer/get_offers/${id}`, {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			})

			dispatch({ type: 'LOAD_OFFERS_BY_ID', payload: res.body })
		} catch (error) {}
	}
}

//Guardar una oferta con imagen en el servidor aws
const createOfferWithImage = (item, files = null) => {
	return async (dispatch, getState) => {
		const state = getState()
		const res = await callApi(`/offer/create_offer`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.user.token,
			},
			body: {
				file: files?.image ? files?.image : null,
				item: JSON.stringify(item),
			},
			successMessage: 'Oferta creada correctamente',
		})

		return res.body
	}
}

//Modificar una oferta con imagen en el servidor aws
const updateOfferWithImage = (item, files = null) => {
	return async (dispatch, getState) => {
		const state = getState()

		// Verificación para asegurar que el ID esté presente
		if (!item.id) {
			throw new Error('The offer ID is missing')
		}

		// Incluye el ID en la URL
		const res = await callApi(`/offer/update_offer/${item.id}`, {
			method: 'PUT',
			json: false,
			headers: {
				Authorization: state.app.user.token,
			},
			body: {
				file: files?.image ? files?.image : null,
				item: JSON.stringify(item),
			},
			successMessage: 'Oferta actualizada correctamente',
		})

		return res.body
	}
}

const deleteOffer = (id) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/offer/delete_offer/${id}`, {
				method: 'DELETE',
				headers: {
					Authorization: state.app.user.token,
				},
				successMessage: 'Registro eliminado correctamente',
			})
		} catch (error) {
			// Manejar error
		}
	}
}

const deleteOffersMultiple = (ids) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/offer/delete_offers_multiple`, {
				method: 'DELETE',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify({ ids }),
				successMessage: 'Registro eliminado correctamente',
			})
		} catch (error) {
			// Manejar error
		}
	}
}

const loadOffersClientPortal = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/offer/load_offers_client_portal`, {
			method: 'GET',
			headers: {
				Authorization: state.app.client.token,
			},
			params: options.params,
		})

		if (res && res.status === 200) {
			dispatch({
				type: `LOAD_${entity.toUpperCase()}S`,
				response: res.body,
			})
		} else {
			console.error('Error al cargar las ofertas:', res)
		}

		return res.body
	}
}

export default {
	...actions,
	getOffers,
	getOffersById,
	createOfferWithImage,
	updateOfferWithImage,
	deleteOffer,
	deleteOffersMultiple,
	loadOffersClientPortal,
}
