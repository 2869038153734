import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
	Message,
	Button,
	Menu,
	Icon,
	Popup,
	Image,
	Grid,
	Label,
	Header,
	Segment,
	Dropdown,
	Modal,
	Divider,
} from 'semantic-ui-react'
import moment from 'moment'

import { getStateValue } from '../../store/selectors/index'
import { logout, setLanguage } from 'store/actions/app'
import userNotifcationActions, {loadUnreadUserNotificationsLastFive} from '../../store/actions/user_notification'
import {getNotifications, markNotificationAsRead, getUnreadNotificationsAmount} from '../../store/actions/client_notifications'
import { getAnnouncementsFromVisitedCompanies } from '../../store/actions/announcements';
import offersActions from '../../store/actions/offers';

import translations from '../../utils/translations'
import './Header.scss'


const getAvatar = (user, superAdmin) => {
	let name = 'Guest'
	if (superAdmin) {
		name = superAdmin?.name
	}
	if (user) {
		name = user?.name
	}
	return `https://ui-avatars.com/api/?name=${name}`
}

const HeaderComponent = (props) => {
	const {
		rest,
		user,
		client,
		logout,
		history,
		expirations,
		setExpand,
		openModal,
		setOpenModal,
		mobileOnly,
		previousExpand,
		setPreviousExpand,
		lang,
		currentTranslations,
		isAuthenticated,
		userNotifications,
		clientNotification,
		announcements,
		offers,
	} = props

	const [modalVisible, setModalVisible] = useState(false)
	const [employes, setEmployees] = useState([])
	const [notificationsCount, setNotificationsCount] = useState(0)
	const [listServiceOrders, setListServiceOrders] = useState([])
	const [pendingOrders, setPendingOrders] = useState([])
	const [delayedOrders, setDelayedOrders] = useState([])
	const [waitingOrders, setWaitingOrders] = useState([])
	const [readAnnouncements, setReadAnnouncements] = useState(new Set())
	const [readOffers, setReadOffers] = useState(new Set());
	const [unreadclientNotif, setUnreadClientNotif] = useState(0);
	const [notifDetails, setNotifDetails] = useState(false);

	const componentDidMount = async () => {
		const res = await props.loadUserNotifications({force: true, params: {user_id: user.id, read: false}})
	}
	useEffect(()=>{
		if(user){
			componentDidMount()
		}
	}, [user])

	const fetchClientNotifications = async () => {
		await props.getNotifications({
			params: {
				sDate: moment().subtract(14, 'days').format(),
				eDate: moment().endOf('month').format(),
			}
		})
		await props.getAnnouncementsFromVisitedCompanies({
			params: {
				currentDate: moment().format(),
			}
		})
		await props.loadOffersClientPortal({
			params: {
				currentDate: moment().format(),
			}
		})
		const res = await props.getUnreadNotificationsAmount()
		setUnreadClientNotif(res)
	}
	useEffect(()=>{
		if(client){
			fetchClientNotifications()
		}
	}, [client])

	useEffect(() => {
		const loadReadAnnouncements = async () => {
		  try{
			//ANNOUNCEMENTS
			const savedReadAnnouncements = localStorage.getItem("readAnnouncements");
	
			if (savedReadAnnouncements) {
			  setReadAnnouncements(new Set(JSON.parse(savedReadAnnouncements)));
			}
	
			//OFFERS
			const savedReadOffers = await localStorage.getItem("readOffers");
	
			if (savedReadOffers) {
			  setReadOffers(new Set(JSON.parse(savedReadOffers)));
			} 
	
		  }catch(e){
		  }
		};
		loadReadAnnouncements();
	  }, []);

	useEffect(() => {
		setPendingOrders(listServiceOrders.filter((o) => o.status === 'PENDIENTE'))
		setDelayedOrders(
			listServiceOrders.filter((order) => order.status === 'ATRASADA')
		)
		setWaitingOrders(
			listServiceOrders.filter((order) => order.status === 'EN ESPERA')
		)
	}, [listServiceOrders])

	useEffect(() => {
		let count =  userNotifications?.filter(userNotif =>!userNotif.read)?.length ?? 0
		count +=  clientNotification?.filter(userNotif =>!userNotif.read)?.length ?? 0
		count +=  announcements?.filter(announcement => !readAnnouncements.has(announcement.id))?.length ?? 0
		count +=  offers?.filter(offer => !readOffers.has(offer.id))?.length ?? 0
		setNotificationsCount(count)
	}, [userNotifications, clientNotification, announcements, readAnnouncements, offers, readOffers, unreadclientNotif])

	let userName = props?.user?.username || props?.superAdmin?.username || props?.client?.email

	return (
		<Menu
			className='NavBar__Header'
			inverted
			borderless
			size='tiny'
			attached='top'
			fixed='top'
		>
			<Menu.Item className='NavBar__Item'>
				<Icon
					className='NavBar__Outline'
					name='list'
					size='large'
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setExpand((previousExpandedState) => {
							setPreviousExpand(previousExpandedState)
							if (!mobileOnly)
								return previousExpandedState == 1
									? previousExpand == null || previousExpand == 2
										? 0
										: 2
									: 1
							return !previousExpandedState ? 1 : 0
						})
					}}
				/>
			</Menu.Item>
			<Menu className='SubMenu__Header' size='mini'>
				<Menu.Item
					onClick={() => {
						history.push(user ? '/' : client ? '/client/company_qr_scanning' : '/')
					}}
				>
					<Header className='NavBar__Item'>
						<h4 className='NavBar__Outline'>Ruf</h4>
						<Header.Subheader className='NavBar__Outline'>
							V 0.1.42
						</Header.Subheader>
					</Header>
				</Menu.Item>
				<Menu.Item fitted position='right'>
					<Icon
						className='Circle_Icon NavBar__Item'
						name='question'
						circular
						onClick={() => {
							setOpenModal(!openModal)
						}}
					/>
				</Menu.Item>
			</Menu>
			<Menu.Menu position={mobileOnly ? null : 'right'} borderless size='tiny'>
				<Menu.Item position='right' fitted className='NavBar__Item'>
					{(
						<Popup
							pinned
							trigger={
								<Menu.Item style={{ marginRight: '1vw', padding: '0 0.8em' }}>
									<Icon
										className='NavBar__Outline'
										size='big'
										name='bell'
										fitted
									>
										{notificationsCount > 0 && (
											<Label
												color='red'
												floating
												circular
												style={{
													fontFamily:
														"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
													fontSize: '0.45em',
												}}
											>
												{notificationsCount}
											</Label>
										)}
									</Icon>
								</Menu.Item>
							}
							content={
								<div style={{overflow: 'auto', maxHeight: '300px'}}>
									{userNotifications?.length == 0 && clientNotification?.length == 0 && announcements?.length == 0 && (
										<Message
											size='tiny'
											warning
											icon='info'
											header={`No hay notificaciones`}
										/>
									)}

									{userNotifications.length > 0 && (
										userNotifications
											?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
											?.map((userNotif, idx)=>(
												<Message
													key={userNotif?.id+idx}
													style={{cursor: 'pointer', backgroundColor: userNotif.read ? '#e2e2e2' : '#dcfce7'}}
													size='tiny'
													onClick={() => {
														if(!userNotif?.read){
															userNotif.read = true
															props.updateUserNotification(userNotif, false)
														}
														setNotifDetails(userNotif)
													}}
													icon='bell'
													header={`${userNotif?.title} `}
													content={`${userNotif?.message}`}
													// content='Click aquí para ver detalles'
												/>
											))
									)}

									{clientNotification.length > 0 && (
										clientNotification.map((clientNotif, idx)=>(
											<Message
												key={clientNotif?.id+idx}
												style={{cursor: 'pointer', backgroundColor: clientNotif.read ? '#e2e2e2' : '#dcfce7'}}
												size='tiny'
												onClick={() => {
													if(!clientNotif?.read){
														props.markNotificationAsRead(clientNotif?.id)
													}
													setNotifDetails(clientNotif)
												}}
												icon='bell'
												header={`${clientNotif?.title} `}
												content={`${clientNotif?.message}`}
												// content='Click aquí para ver detalles'
											/>
										))
									)}
									
									{announcements.length > 0 && (
										announcements.map((announcement, idx)=>(
											<Message
												key={announcement?.id+idx}
												style={{cursor: 'pointer', backgroundColor: readAnnouncements.has(announcement.id) ? '#e2e2e2' : '#dcfce7'}}
												size='tiny'
												onClick={() => {
													if(!readAnnouncements.has(announcement.id)){
														setReadAnnouncements((prev) => { 
															localStorage.setItem(
																"readAnnouncements",
																JSON.stringify(Array.from(new Set(prev).add(announcement.id)))
															)
	
															return new Set(prev).add(announcement.id)
														})
													}
													setNotifDetails({...announcement, title: announcement?.name, image: announcement?.image_url, message: announcement?.description,})
												}}
												icon='bullhorn'
												header={`${announcement?.SysCompany?.name} dice: \n${announcement?.name} `}
												content={(
													<text style={{
														display: '-webkit-box',
														'-webkit-line-clamp': '2', /* Número de líneas */
														'-webkit-box-orient': 'vertical',
														overflow: 'hidden',
													  }}>{announcement?.description}</text>
												)}
											/>
										))
									)}

									{offers.length > 0 && (
										offers.map((offer, idx)=>(
											<Message
												key={offer?.id+idx}
												style={{cursor: 'pointer', backgroundColor: readOffers.has(offer.id) ? '#e2e2e2' : '#dcfce7'}}
												size='tiny'
												onClick={() => {
													if(!readOffers.has(offer.id)){
														setReadOffers((prev) => { 
															localStorage.setItem(
																"readOffers",
																JSON.stringify(Array.from(new Set(prev).add(offer.id)))
															)
	
															return new Set(prev).add(offer.id)
														})
													}
													setNotifDetails({...offer, title: offer?.promotion_name, message: offer?.description,})
												}}
												icon='bullhorn'
												header={`${offer?.SysCompany?.name} dice: \n${offer?.promotion_name} `}
												content={(
													<text style={{
														display: '-webkit-box',
														'-webkit-line-clamp': '2', /* Número de líneas */
														'-webkit-box-orient': 'vertical',
														overflow: 'hidden',
													  }}>{offer?.description}</text>
												)}
											/>
										))
									)}

								</div>
							}
							position='bottom right'
							on='click'
						/>
					)}
				</Menu.Item>
				<Menu.Item fitted position='right' className='NavBar__Item'>
					<Popup
						onOpen={() => setModalVisible(true)}
						onClose={() => setModalVisible(false)}
						pinned
						on='click'
						trigger={
							<Menu.Item className='NavBar__Item'>
								<Image src={getAvatar(user, props.superAdmin)} avatar />
							</Menu.Item>
						}
						content={
							<Segment className='NavBar__Outline'>
								<Grid
									divided='vertically'
									columns='equal'
									className='Header_PopUp'
									style={{ display: 'inline-block', padding: 0 }}
								>
									<Grid.Row columns={1}>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Image
												className='Header_PopUp_Image'
												src={getAvatar(user, props.superAdmin)}
												avatar
											/>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Dropdown
												options={[{ key: 'en', value: 'en', text: 'English' }, 
												{ key: 'es', value: 'es', text: 'Español' },]}
												placeholder='Seleccione un idioma.'
												style={{color: 'black'}}
												value={lang}
												onChange={(e, {value})=>{
													props.setLanguage(value)
												}}
											/>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<h4 className='NavBar__InvertedOutline'>
												{userName?.toUpperCase()}
											</h4>
										</Grid.Column>
										<Grid.Column
											verticalAlign='middle'
											textAlign='center'
											className='Logout__Item'
										>
											<Button
												className='Header_PopUp_Button'
												basic
												icon
												labelPosition='right'
												onClick={() => {
													const query = (document.URL.indexOf('?') != -1) ? ('?'+document.URL.split('?')[1]) : ''
													if(isAuthenticated){
														logout()
														props.history.push('/login')
													}else{
														props.history.push('/login_client'+query)
													}
												}}
											>
												<Icon name='sign out' />

												{isAuthenticated ? currentTranslations.logOut : currentTranslations.logIn}
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						}
						position='bottom right'
					/>
				</Menu.Item>
			</Menu.Menu>
			<Modal
				closeIcon
				basic
				centered={true}
				onClose={() => setNotifDetails(false)}
				onOpen={() => setNotifDetails(true)}
				open={notifDetails}
				size='mini'
			>
				<Modal.Content content>
					<Segment inverted style={{ height: '100%' }}>
						<Grid stackable centered>
							<Grid.Column width={16}>
								<Header inverted content={notifDetails?.SysCompany?.name} />
								<Divider></Divider>
							</Grid.Column>
							<Grid.Column width={16}>
								<Header
									size='small'
									inverted
									content={notifDetails?.title}
								/>
							</Grid.Column>
							<Grid.Column width={16}>
								<Image 
									src={notifDetails?.image} 
									// alt="Correo" 
									size="medium" 
									centered 
								/>
							</Grid.Column>
							<Grid.Column width={16}>
								<text style={{}}>{notifDetails?.message}</text>
							</Grid.Column>
							<Grid.Column width={16}>
								<text style={{}}>{moment(notifDetails.createdAt).format("LL, h:mm a")}</text>
							</Grid.Column>

						</Grid>
					</Segment>
				</Modal.Content>
			</Modal>
		</Menu>
	)
}

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
	setLanguage: (lang) => dispatch(setLanguage(lang)),
	loadUserNotifications: (options) => dispatch(loadUnreadUserNotificationsLastFive(options)),
	updateUserNotification: (item, shouldShowSuccessMessage) => dispatch(userNotifcationActions.update(item, shouldShowSuccessMessage)),
	getNotifications: (options) => dispatch(getNotifications(options)),
	markNotificationAsRead: (notificationId, markAll) => dispatch(markNotificationAsRead(notificationId, markAll)),
	getAnnouncementsFromVisitedCompanies: (options) => dispatch(getAnnouncementsFromVisitedCompanies(options)),
	loadOffersClientPortal: (options) => dispatch(offersActions.loadOffersClientPortal(options)),
	getUnreadNotificationsAmount: () => dispatch(getUnreadNotificationsAmount()),
})

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.user || !!state.app.client || !!state.app.superAdmin,
	user: state.app?.user,
	superAdmin: state.app?.superAdmin,
	client: state.app.client,
	expirations: state.document_items?.expiration_report ?? [],
	lang: state.app.lang ?? 'en',
	currentTranslations: translations[state.app.lang],
	userNotifications: getStateValue(state.user_notifications.entities),
	clientNotification: getStateValue(state.client_notifications.entities),
	announcements: getStateValue(state.announcements.entities),
	offers: getStateValue(state.offers.entities),
	
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
