import crud from "./_crud";
import { callApi } from "../../utils/api";

const entity = "user_notification";
const messageEntity = "la notificación";
const messagePluralEntity = "las notificacións";
const reducerEntity = "user_notification";

const actions = crud(entity, messageEntity, messagePluralEntity);

export const loadUnreadUserNotificationsLastFive = (options = {}) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const res = await callApi(`/${entity}/unreadNotifications/lastFive`, {
        method: "GET",
        headers: {
          Authorization: state.app?.user?.token,
        },
        params: options.params,
      });

      if (res.status == 200) {
        dispatch({
          type: `LOAD_${reducerEntity.toUpperCase()}S`,
          response: res.body,
        });

        return res.body;
      }

      return [];
    } catch (e) {
      return [];
    }
  };
};

export default actions;
