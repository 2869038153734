import crudHandlers from "./_crud";
import { convertToArrayObject } from "../../utils/index";

const entity = "client_notification";

const initialState = {
  entities: {},
  is_fetching: false,
  active: undefined,
};

export default (state = initialState, action) => {
  const { type, response } = action;
  switch (type) {
    case `READ_ALL`: {
      const entities = {}

      for(let client_notification in state.entities){
        entities[client_notification] = {...state.entities[client_notification], read:true}
      }

      return {
        ...state,
        entities,
      };
    }
    case `LOAD_${entity.toUpperCase()}S_AND_REMAINS`: {
      //  It first transform the array into an object.
      let items = convertToArrayObject(response);
      return {
        ...state,
        entities: { ...state.entities, ...items },
        is_fetching: false,
      };
    }

    case 'LOGOUT': {
      return initialState;
    }
    
    default:
      state = crudHandlers(state, action, entity);

      return state;
  }
};
