import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import {
	Button,
	Card,
	Segment,
	Grid,
	Image,
	Select,
	Flag,
	Dropdown,
	Icon,
} from 'semantic-ui-react'
import ladas from 'utils/ladas'
import { Form as SemanticForm } from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import Input from 'components/InputForm'
import { useDispatch } from 'react-redux'
import { registerClient } from '../../store/actions/clients'
import { createSysCompany } from '../../store/actions/clients'

import LogoImg from '../../images/logo1024.jpg'

const validateForm = (values, formularioVisible, hide_email_input) => {
	const errors = {}
	if (formularioVisible === 'client') {
		// Validación específica para el formulario de cliente
		if (!values.nameClient) {
			errors.nameClient = 'El nombre del cliente es obligatorio'
		}
		if (!values.cellphoneClient) {
			errors.cellphoneClient = 'Campo obligatorio'
		} else {
			if (String(values.cellphoneClient).length !== 10) {
				errors.cellphoneClient = 'Formato incorrecto: Número a 10 dígitos'
			}
		}
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
		if (!values.emailClient && !hide_email_input) {
			errors.emailClient = 'Campo obligatorio'
		} else {
			if (!emailRegex.test(values.emailClient) && !hide_email_input) {
				errors.emailClient =
					'Formato incorrecto: Ingrese un formato de correo válido'
			}
		}
		if (values.passwordClient !== values.passwordConfirmClient) {
			errors.passwordConfirmClient = 'La contraseña no coincide'
		}
		if (!values.passwordClient) {
			errors.passwordClient = 'Campo obligatorio'
		}
		//Validaciones para compañía
	} else if (formularioVisible === 'company') {
		if (!values.nameRestaurant) {
			errors.nameRestaurant = 'El nombre de la compañía es obligatorio'
		}
		if (!values.cellphoneRestaurant) {
			errors.cellphoneRestaurant = 'Campo obligatorio'
		} else {
			if (String(values.cellphoneRestaurant).length !== 10) {
				errors.cellphoneRestaurant = 'Formato incorrecto: Número a 10 dígitos'
			}
		}
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
		if (!values.emailRestaurant) {
			errors.emailRestaurant = 'Campo obligatorio'
		} else {
			if (!emailRegex.test(values.emailRestaurant)) {
				errors.emailRestaurant =
					'Formato incorrecto: Ingrese un formato de correo válido'
			}
		}
		if (values.passwordRestaurant !== values.passwordConfirmRestaurant) {
			errors.passwordConfirmRestaurant = 'La contraseña no coincide'
		}
		if (!values.passwordRestaurant) {
			errors.passwordRestaurant = 'Campo obligatorio'
		}
	}
	return errors
}

const handleCellphoneInput = (event) => {
	let value = event.target.value

	value = value.replace(/[^0-9]/g, '')

	if (value.length > 10) {
		value = value.slice(0, 10)
	}

	event.target.value = value
}

const handleTableInput = (event) => {
	let value = event.target.value

	value = value.replace(/[^0-9]/g, '')

	if (value !== '') {
		const numericValue = parseInt(value, 10)
		if (numericValue < 1) {
			value = '1'
		} else {
			value = numericValue.toString()
		}
	}
	if (value.length > 2) {
		value = value.slice(0, 2)
	}
	event.target.value = value
}

const countries = [
	{ value: '+52', flag: 'mx', text: '+52' },
	{ value: '+1', flag: 'us', text: '+1' },
]

const AccountRegister = (props) => {
	const dispatch = useDispatch()
	const [formularioVisible, setFormularioVisible] = useState(null)
	const [selectedLada, setSelectedLada] = useState(countries[0])

	const handleLadaChange = (e, { value }) => {
		const selectedOption = countries.find((lada) => lada.value === value)
		setSelectedLada(selectedOption)
	}

	// un useEffect para leer los parametros de la url
	const location = useLocation()
	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const type = params.get('type')
		if (type === 'client') {
			mostrarFormularioCliente()
		} else if (type === 'company') {
			mostrarFormularioCompañía()
		}
	}, [location])

	const mostrarFormularioCliente = () => {
		setFormularioVisible('client')
	}

	const mostrarFormularioCompañía = () => {
		setFormularioVisible('company')
	}

	return (
		<div
			style={{
				width: '100wh',
				height: '140vh',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '20px',
			}}
			className='login-background'
		>
			<div
				style={{
					display: 'flex',
					height: '20vh',
					position: 'fixed',
					top: 0,
					left: 0,
				}}
			></div>
			<Grid centered>
				<Grid.Row>
					<Grid.Column computer={6} tablet={8} mobile={16}>
						<Segment>
							<Grid stackable>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<div
										onClick={() => {
											window.location.href = '/login'
										}}
										style={{ cursor: 'pointer' }}
									>
										<Image
											src={process.env.REACT_APP_LOGO_LOGIN_URL ?? LogoImg}
											size='small'
										/>
									</div>
								</Grid.Row>
								<Grid.Row
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Grid.Column
										width={16}
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
										}}
									>
										<Card>
											<Card.Content>
												<Card.Header textAlign='center' style={{ padding: 10 }}>
													Crear cuenta como
												</Card.Header>
												<Grid.Row verticalAlign='center'>
													<Button.Group toggle>
														<Button
															style={{ 
																width: 110, 
																backgroundColor: (formularioVisible !== 'client') ? 'white' : '#00bcbd', 
																color: (formularioVisible !== 'client') ? '#00bcbd' : 'white', 
																border: '2px solid #00bcbd'
															}}
															onClick={mostrarFormularioCliente}
														>
															Cliente
														</Button>
														<Button.Or text='O' />
														<Button
															style={{ 
																width: 110, 
																backgroundColor: (formularioVisible !== 'company') ? 'white' : '#00bcbd', 
																color: (formularioVisible !== 'company') ? '#00bcbd' :  'white', 
																border: '2px solid #00bcbd',
															}}
															onClick={mostrarFormularioCompañía}
														>
															Compañía
														</Button>
													</Button.Group>
												</Grid.Row>
											</Card.Content>
										</Card>
									</Grid.Column>
									<Grid.Column
										width={16}
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
											marginTop: 10,
										}}
										textAlign='left'
									>
										<Formik
											initialValues={{
												numTables: '0',
												numWaiters: '0',
											}}
											validate={(values) =>
												validateForm(values, formularioVisible, props.hide_email_input)
											}
											onSubmit={async (values, { setSubmitting }) => {
												if (formularioVisible === 'client') {
													// Realizar acciones para el registro de cliente
													const obj = {
														name: values.nameClient,
														cellphone: values.cellphoneClient,
														email: values?.emailClient?.toLowerCase(),
														password: values.passwordClient,
													}

													obj.lada = selectedLada.value
													dispatch(registerClient(obj))
												} else if (formularioVisible === 'company') {
													// Realizar acciones para el registro de compañía
													const sysCompanyData = {
														name: values.nameRestaurant,
														phone: values.cellphoneRestaurant,
													}
													sysCompanyData.lada = selectedLada.value
													const tableAndWaitersData = {
														tables: values.numTables,
														waiters: values.numWaiters,
													}
													const userData = {
														username: values.emailRestaurant.toLowerCase(),
														password: values.passwordRestaurant,
													}
													dispatch(
														createSysCompany(
															sysCompanyData,
															userData,
															tableAndWaitersData
														)
													)
												}
											}}
										>
											{({ isSubmitting }) => (
												<Form className='ui form'>
													{formularioVisible === 'client' && (
														// {Formulario de cliente}
														<Card>
															{/* <Card.Content textAlign='center'>
															<span style={{ fontSize: 16 }}>
																Cuenta como cliente
															</span>
														</Card.Content> */}
															<Card.Content>
																<Field
																	type='text'
																	name='nameClient'
																	component={Input}
																	label='Nombre'
																	placeholder='Nombre del cliente'
																/>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'flex-start', // Esto alinea el contenido hacia arriba
																		gap: '8px',
																		marginTop: 5,
																	}}
																>
																	<SemanticForm.Field>
																		<div>Lada</div>
																		<Dropdown
																			placeholder='Selecciona una lada'
																			compact
																			selection
																			options={countries}
																			value={selectedLada.value}
																			onChange={handleLadaChange}
																			trigger={
																				<span>
																					<Flag name={selectedLada.flag} />{' '}
																					{selectedLada.value}
																				</span>
																			}
																		/>
																	</SemanticForm.Field>

																	<Field
																		style={{ flex: '1' }}
																		name='cellphoneClient'
																		component={Input}
																		label='Teléfono'
																		placeholder='Número de teléfono'
																		onInput={handleCellphoneInput}
																	/>
																</div>

																{!props.hide_email_input && (
																	<Field
																		type='email'
																		name='emailClient'
																		component={Input}
																		label='Correo electrónico'
																		placeholder='Correo electrónico'
																	/>
																)}
																<div style={{marginTop: 10}}>
																	<Field
																		type='password'
																		name='passwordClient'
																		component={Input}
																		label='Contraseña'
																		placeholder='Contraseña'
																	/>
																</div>
																<div style={{marginTop: 10}}>
																	<Field
																		type='password'
																		name='passwordConfirmClient'
																		component={Input}
																		label='Repetir contraseña'
																		placeholder='Repetir contraseña'
																	/>
																</div>
																<Button
																	style={{
																		margin: '1rem 0',
																		width: '100%',
																	}}
																	className='general-color'
																	type='submit'
																	content={'Registrarse'}
																/>
															</Card.Content>
														</Card>
													)}
													{formularioVisible === 'company' && (
														// {Formulario de cliente}
														<Card>
															<Card.Content>
																<Field
																	type='text'
																	name='nameRestaurant'
																	component={Input}
																	label='Nombre'
																	placeholder='Nombre de la compañía'
																/>

																<div
																	style={{
																		display: 'flex',
																		alignItems: 'flex-start',
																		gap: '8px',
																		marginTop: 5,
																	}}
																>
																	<SemanticForm.Field>
																		<div>Lada</div>
																		<Dropdown
																			placeholder='Selecciona una lada'
																			compact
																			selection
																			options={countries}
																			value={selectedLada.value}
																			onChange={handleLadaChange}
																			trigger={
																				<span>
																					<Flag name={selectedLada.flag} />{' '}
																					{selectedLada.value}
																				</span>
																			}
																		/>
																	</SemanticForm.Field>
																	<Field
																		style={{ flex: '1' }}
																		name='cellphoneRestaurant'
																		component={Input}
																		label='Teléfono'
																		placeholder='Número de teléfono'
																		onInput={handleCellphoneInput}
																	/>
																</div>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '8px',
																		marginTop: 5,
																	}}
																>
																	<Field
																		style={{ width: '100%' }}
																		type='text'
																		name='numTables'
																		component={Input}
																		label='Estaciones'
																		placeholder='0'
																		onInput={handleTableInput}
																	/>
																	<Field
																		style={{ width: '100%' }}
																		type='text'
																		name='numWaiters'
																		component={Input}
																		label='Nº Asistentes'
																		placeholder='0'
																		onInput={handleTableInput}
																	/>
																</div>

																<div style={{ marginTop: 10 }}>
																	<Field
																		type='email'
																		name='emailRestaurant'
																		component={Input}
																		label='Correo electrónico'
																	/>
																</div>
																<div style={{marginTop: 10}}>
																	<Field
																		type='password'
																		name='passwordRestaurant'
																		component={Input}
																		label='Contraseña'
																	/>
																</div>
																<div style={{marginTop: 10}}>
																	<Field
																		type='password'
																		name='passwordConfirmRestaurant'
																		component={Input}
																		label='Repetir contraseña'
																	/>
																</div>

																<Button
																	style={{
																		margin: '1rem 0',
																		width: '100%',
																	}}
																	className='general-color'
																	type='submit'
																	content={'Registrarse'}
																/>
															</Card.Content>
														</Card>
													)}
												</Form>
											)}
										</Formik>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Link
										to={
											formularioVisible === 'client'
												? '/login_client'
												: '/login'
										}
										style={{ color: 'black', fontSize: 15, marginBottom: 10 }}
									>
										¿Ya tienes cuenta?{' '}
										<span style={{ color: '#0e1d48' }}>Inicia sesión aquí</span>
									</Link>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	)
}

const mapStateToProps = (state) => ({
	hide_email_input: state.access?.clientPermissions?.hide_email_input,
  });

export default connect(mapStateToProps)(AccountRegister)
