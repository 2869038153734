import Rect, {useMemo} from 'react'
import { Breadcrumb, Tab } from 'semantic-ui-react'
import { connect } from 'react-redux'

import PrintQR from '../../components/PrintQR'

const RestroomPage = (props) => {
    const {
        sys_company_id,
    } = props

    const linkRestroomCode = useMemo(()=>{
		return `${process.env.REACT_APP_ENDPOINT}/client/company_qr_scanning?lang=${props?.lang}&scan_qr_web=${props?.scan_qr_web}&restroomCode=${props?.sys_company_id}B`
	}, [props.lang, props?.scan_qr_web, props?.sys_company_id])

    const restroomCode = sys_company_id + 'B'

    const panes = [{
            menuItem: 'Código QR',
            tabIndex: 0,
            render: () => (
                <PrintQR code={linkRestroomCode} label={restroomCode}/>
            )
        },
    ]

    return (
        <div className='PageContainer'>
            <Breadcrumb icon='right angle' sections={[{key: 'Baño', content: 'Baño'}]} />
			<Tab panes={panes} menu={{ secondary: true, pointing: true }} />
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

})

const mapStateToProps = (state) => ({
	user: state.app.user,
    scan_qr_web: state.app?.user?.company?.scan_qr_web,
    sys_company_id: state.app.user?.sys_company_id,
    lang: state.app.lang,
})

export default connect(mapStateToProps, mapDispatchToProps)(RestroomPage)