import RestroomPage from "routes/mantenimientos/RestroomPage";
import { callApi } from "../../utils/api";
import crud from './_crud'

const entity = 'client_notifications'
const messageEntity = 'La notificación'
const messagePluralEntity = 'Las notificaciones'
const reducerEntity = 'client_notification'
const actions = crud(entity, messageEntity, messagePluralEntity, reducerEntity);

export const getNotifications = (options={}) => {
    return async (dispatch, getState) => {
        const state = getState();
        try {
            const res = await callApi(`/client_notifications`, {
                method: 'GET',
                headers: {
                    Authorization: state.app.client.token,
                },
                params: options.params
            });

            if(res.status == 200){
                dispatch({
                    type: `LOAD_${reducerEntity.toUpperCase()}S_AND_REMAINS`,
                    response: res.body,
                })
                
                return RestroomPage.body;
            }
            
            return []
        } catch (error) {
            return []
        }
    };
}

export const markNotificationAsRead = (notificationId, markAll = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        try {
            let url = '/client_notifications/mark_as_read';
            if(markAll) url += '?all=true'

            const response = await callApi(url, {
                method: 'PUT',
                headers: {
                    Authorization: state.app.client.token,
                },
                body: JSON.stringify({ notification_id: notificationId }),
                
            });
        
            if(response.status === 200) {
                if(notificationId){
                    dispatch({
                        type: `UPDATE_${reducerEntity.toUpperCase()}`,
                        response: {id: notificationId, read:true},
                    })
                }
                if(markAll){
                    dispatch({
                        type: 'READ_ALL'
                    })
                }
                return true;
            }   
            return false;
        } catch (error) {
            throw error;
        }
    };
}

export const getUnreadNotificationsAmount = () => {
    return async (dispatch, getState) => {
        const state = getState();
        try {
            const res = await callApi(`/client_notifications/unread/amount`, {
                method: 'GET',
                headers: {
                    Authorization: state.app.client.token,
                },
                shouldSkipStatusError: true,
            });

            if(res.status == 200 && !isNaN(res?.body?.unreadNotifications) && res?.body?.unreadNotifications>0){
                return res?.body?.unreadNotifications
            }
            return 0
        } catch (error) {
            return 0
        }
    };
}

export default actions