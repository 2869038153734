import React from "react";
import { Button } from 'semantic-ui-react'
import QrGen from 'qrcode.react'

const PrintQR = (props) => {
    const {
        code = '',
        label = '',
    } = props

    const printQr = () => {
        const containterPrint = document.getElementById('containterPrint')

        if (containterPrint) {
            document.body.prepend(containterPrint);  // Esto moverá el div al principio de body
        }

        window.print()
    }

    return (
        <>
            <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                <QrGen
                    value={code}
                    size={320}
                    includeMargin
                />
                <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                    <p style={{fontWeight: 'bold', fontSize: 30}}>Código: {label}</p>
                    <Button onClick={printQr}><i class="print icon"></i> Imprimir QR </Button>
                </div>
            </div>    
            <div id='containterPrint' style={{ visibility: 'hidden', maxHeight: '0px', overflow: 'hidden' }} >
                <div
                    id='divPrint'
                    style={{
                        visibility: 'hidden',
                        overflow: 'hidden',
                        width: '100%',
                        fontSize: '6px',
                        lineHeight: '0px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column', alignItems: 'center',
                    }}
                >
                    <style>
                        {`@media print {
                            body * {
                                visibility: hidden;
                                overflow: hidden;
                            }
                            #divPrint, #divPrint * {
                                visibility: visible;
                            }
                            #divPrint {
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }`
                        }
                    </style>
                    <QrGen
                        value={code}
                        size={480}
                        includeMargin
                    />
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                        <p style={{fontWeight: 'bold', fontSize: 40}}>Código: {label}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrintQR