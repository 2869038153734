import crudHandlers from './_crud'

const initialState = {
	clients: [],
	amount: 0,
}

export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOAD_CLIENTS': {
			return {
				...state,
				clients: action.response ?? []
			}
		}
		case 'LOAD_AMOUNT_OF_CLIENTS': {
			return {
				...state,
				amount: typeof payload == 'number' ? payload : 0 
			}
		}
		default:
			state = crudHandlers(state, action, 'client')

			return state
	}
}
