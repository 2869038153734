import { showErrorMessage, showSuccessMessage } from "./messages";

export const callApi = async (url, options = {}) => {
  let {
    json = true,
    json_response = true,
    params = {},
    shouldBuildUrl = true,
    method = 'GET',
    headers = {},
    successMessage = undefined,
    errorMessage = undefined,
    shouldSkipStatusError = false
  } = options

  if (json) {
    headers = {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  } else {
    if (method !== 'GET' && options.body) {
      let formBody = new FormData();
      for (var key in options.body) {
        if (Array.isArray(options.body[key])) {
          for (var subKey in options.body[key]) {
            formBody.append(key, options.body[key][subKey]);
          }
        } else {
          formBody.append(key, options.body[key]);
        }

      }

      options.body = formBody;
    }
  }

  //  Build URL
  const fullURL = shouldBuildUrl ? new URL(`${process.env.REACT_APP_API_ENDPOINT}${url}`) : new URL(url);

  //  Add custom query parameters
  Object.keys(params).forEach(key => fullURL.searchParams.append(key, params[key]))

  try {
    const response = await fetch(fullURL, {
      ...options,
      method,
      headers
    })

    let body
    try{
      body = json_response ? await response.json() : response  
    }catch(e){

    }

    if (!shouldSkipStatusError) {
      if (response.status !== 200) {
        throw { message: (errorMessage ? errorMessage : body), statusError: true, status: response.status };
      } else if (successMessage) {
        showSuccessMessage(successMessage);
      }
    }

    return {
      status: response.status,
      body
    }
  } catch (e) {
    //  Return to login page if the request fail with 401
    if(e.status === 401){
      localStorage.removeItem('TOKEN');
      showErrorMessage(e.message);
      return;
    }
    if (e.statusError) {
      showErrorMessage(e.message);
    } else {
      showErrorMessage('No se pudo conectar con el Servidor. Contacte al administrador');
    }

    throw e;
  }
}

export const fetchRaw = async (url, options) => {
  const res = await fetch(url, options);
  if(res.status !== 200){
      const body = await res.json()
      return Promise.reject(body)
  }else{
      return res.blob()
  }
};